<template>
    <div class="home">
        <div v-masonry transition-duration=".3s" item-selector=".item">
            <div v-masonry-tile v-for="photo in photos" :key="photo.md5" class="item">
                <router-link :to="{name: 'ViewPhoto', params: {id: photo.id}}" @click.native="selectPhoto(photo)">
                    <img :src="photo.uri[size]">
                </router-link>
            </div>
        </div>
        <Overlay :visible="$route.name == 'ViewPhoto'" class="overlay">
            <router-view
                :photo="current.photo"
                :key="$route.params.id"
                @previous="previous"
                @next="next">
            </router-view>
        </Overlay>
    </div>
</template>

<script>
import Overlay from '@/components/Overlay'
import VueScreenSize from 'vue-screen-size'

export default {
    name: 'Photos',
    data() {
        return {
            photos: [],
            total: 0,
            columnWidth: 100,
            current: {
                photo: null,
                index: -1
            },
            loading: false
        }
    },
    computed: {
        label() {
            return this.$store.state.activeLabel
        },
        size() {
            const dimension = Math.max(this.$vssHeight, this.$vssWidth)
            if (dimension > 4096) return 'xl'
            else if (dimension > 1400) return 'lg'
            else if (dimension > 390) return 'md'
            else return 'sm'
        }
    },
    components: {
        Overlay
    },
    methods: {
        loadPhotos() {
            if (!this.loading) {
                // TODO keep loading until scrollbar appears?
                this.loading = true
                const params = {}
                if (this.photos.length) {
                    params.from = this.photos.length
                }
                if (this.$store.state.activeLabel) {
                    params.label = this.$store.state.activeLabel.id
                }
                return this.axios.get('photos/', {params: params})
                .then(resp => {
                    this.photos.push(...resp.data.results)
                    this.total = resp.data.total
                    this.loading = false
                })
                .catch(() => {})
            }
        },
        selectPhoto(photo) {
            this.current.photo = photo
            this.current.index = this.photos.indexOf(photo)
            this.$store.commit('bindKeys', this.keyBindings)
        },
        onScroll() {
            if (this.photos.length != this.total && (window.innerHeight + window.scrollY + 100) >= document.body.offsetHeight) {
                this.loadPhotos()
            }
        },
        previous() {
            if (this.current.index > 0) {
                this.current.index = this.current.index - 1
                this.current.photo = this.photos[this.current.index]
                this.$router.push({name: 'ViewPhoto', params: {id: this.current.photo.id}})
            }
            else {
                this.$router.push({name: 'Photos'})
            }
        },
        next() {
            if (this.current.index < this.total - 1) {
                this.current.index = this.current.index + 1
                if (this.current.index < this.photos.length) {
                    this.current.photo = this.photos[this.current.index]
                    this.$router.push({name: 'ViewPhoto', params: {id: this.current.photo.id}})
                }
                else {
                    this.loadPhotos().then(() => {
                        this.current.photo = this.photos[this.current.index]
                    })
                }
            }
            else {
                this.$router.push({name: 'Photos'})
            }
        },
        keyBindings(e) {
            switch (e.key) {
                case 'ArrowLeft':
                    this.previous()
                    break
                case 'ArrowRight':
                    this.next()
                    break
            }
        }
    },
    mixins: [
        VueScreenSize.VueScreenSizeMixin
    ],
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (to.name == 'ViewPhoto') {
                vm.axios.get('photo/', {params: {id: to.params.id}})
                .then(resp => {
                    vm.current.photo = resp.data.photo
                })
            }
        })
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll)
    },
    watch: {
        label() {
            this.photos = []
            this.total = 0
            this.loadPhotos()
        }
    }
}
</script>

<style scoped>
.item {
    width: 25%;
    padding: .5rem;
}
img {
    display: block;
    max-width: 100%;
    max-height: 100%;
}
.overlay {
    z-index: 5;
}
@media (max-width: 1024px) {
    .item {
        width: 33.33%;
    }
}
@media (max-width: 730px) {
    .item {
        width: 50%;
    }
}
</style>