<template>
    <transition name="fade" v-bind="$attrs" v-on="$listeners">
        <div class="overlay" v-show="visible">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Overlay',
    props: {
        visible: Boolean
    }
}
</script>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: #222;
}
</style>